html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}


body {
  box-sizing: border-box;
  padding: 24px;
  max-width: 1280px;
  margin: 0 auto;
}

button.back-button {
  display: block;
  width: auto !important;
  margin-bottom: 12px;
}

.app-wrapper {
  position: relative;

  .config {
    display: block;
    position: absolute;
    z-index: 10;
    height: 2.0rem;
    line-height: 2.0rem;
    top: 0;
    right: 0;

    .material-icons {
      display: block;
      font-size: 1rem;
      line-height: 0.9rem;
    }
  }
}

/* INPUT TYPE = RANGE */
.app-wrapper {
  input[type=range] {
    border: none;
  }
}

.app-wrapper {
  input[type=range] {
    width: 100%;
    margin: 17.5px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    background: #00f8f8;
    border: 0.2px solid #00f8f8;
    border-radius: 1.3px;
    width: 100%;
    height: 1px;
    cursor: pointer;
  }

  input[type=range]::-webkit-slider-thumb {
    margin-top: -17.7px;
    width: 16px;
    height: 36px;
    background: #00f8f8;
    border: 1px solid #000000;
    border-radius: 3px;
    cursor: pointer;
    -webkit-appearance: none;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #13ffff;
  }

  input[type=range]::-moz-range-track {
    background: #00f8f8;
    border: 0.2px solid #00f8f8;
    border-radius: 1.3px;
    width: 100%;
    height: 1px;
    cursor: pointer;
  }

  input[type=range]::-moz-range-thumb {
    width: 16px;
    height: 36px;
    background: #00f8f8;
    border: 1px solid #000000;
    border-radius: 3px;
    cursor: pointer;
  }

  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 18.5px 0;
    color: transparent;
    width: 100%;
    height: 1px;
    cursor: pointer;
  }

  input[type=range]::-ms-fill-lower {
    background: #00dedf;
    border: 0.2px solid #00f8f8;
    border-radius: 2.6px;
  }

  input[type=range]::-ms-fill-upper {
    background: #00f8f8;
    border: 0.2px solid #00f8f8;
    border-radius: 2.6px;
  }

  input[type=range]::-ms-thumb {
    width: 16px;
    height: 36px;
    background: #00f8f8;
    border: 1px solid #000000;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }

  input[type=range]:focus::-ms-fill-lower {
    background: #00f8f8;
  }

  input[type=range]:focus::-ms-fill-upper {
    background: #13ffff;
  }

  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align:auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type=range] {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }

}
