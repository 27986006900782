.home-page {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-items: center;
  justify-content: center;

  h1, p, button {
    display: block;
  }

  & > .arwes-frame {
    display: block;
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
  }
}
