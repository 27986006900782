.shiplist-page {

  h1 {
    margin-bottom: 4px;
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    gap: 48px;

    li {
      display: flex;
      justify-items: stretch;
      flex: 1 1 300px;


      a {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
      }


      figure.arwes-figure {
        margin-bottom: 0;

        img {
          min-width: initial;
        }
      }

      button.arwes-frame {
        width: 100%;
      }

      p.arwes-text {
        display: block;
        text-align: end;
        padding-right: 8px;
        color: #00f8f8;
      }
    }
  }

}
