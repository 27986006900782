
.analyzescreen-page {
  h1 {
    display: block;
    margin-top: 24px;
    margin-bottom: 6px;
  }
    ul li {
      margin-bottom: 12px;
    }

  .critical {
    color: darkred;
  }

  .warning {
    color: darkorange;
  }
}
