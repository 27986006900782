.config-page {
  h1, h2, h3, h4, h5, h6 {
    display: block;
  }

  h3 {
    font-size: 0.8rem;
  }

  .module-list-wrapper {
    h2 {
      margin-bottom: 12px;
    }

    .open-close-buttons {
      padding-bottom: 24px;
    }
  }


  ul {
    padding: 0 0 0 8px;
    margin: 0;
    border-left: 1px solid #00f8f8;

    li::marker {
      content: "";
    }
  }

  div > ul {
    padding-left: 0;
    border: none;
  }

  li {
    margin-bottom: 12px;

    & > h3 {
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: -7px;

      .material-icons {
        vertical-align: middle;
        margin-top: -5px;
        margin-right: 7px;
      }
    }


    &:not(.closed) {
      & > h3 {
        .visible-on-closed {
          display: none;
        }

        .visible-on-open {
          display: inline-block;
        }
      }
    }

    &.closed {
      & > h3 {
        .visible-on-closed {
          display: inline-block;
        }

        .visible-on-open {
          display: none;
        }
      }

      & > ul {
        display: none;
      }
    }
  }


  li.flex-row {
    display: flex;
    flex-flow: row wrap;

    & > * {
      flex: 1;
    }
  }

  .quick-settings {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .probability-text {
    position: static;
    display: block;
    margin-top: -23px;
    margin-bottom: 12px;
  }

  .probability-wrapper {
    position: relative;


    .probability-field-wrapper {


      input {
        padding-top: 18px;
        padding-bottom: 18px;
      }
    }
  }
}
