
.ship-overview-page {
  padding-bottom: 48px;

  h1.arwes-text {
    margin-bottom: 0;
  }

  h2.arwes-text {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  p.arwes-text {
    display: block;
    margin-bottom: 0;
    padding-right: 12px;
  }

  figure.arwes-figure {
    margin-bottom: 12px;
  }

  button.arwes-frame {
    display: block;
    width: 100%;
  }

  hr {
    margin-bottom: 12px;
  }

  .wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 24px;
  }

  .module-list-wrapper {
    flex: 1 0 350px;
  }

  .shipModuleList {
    list-style-type: none;
    padding: 0;
    margin: 12px 0 0 0;

    li {
      cursor: pointer;
      display: block;
      margin-bottom: 12px;

      .arwes-frame {
        display: block;
        width: 100%;
        text-align: start;

        .arwes-frame__content {
          display: flex;
          flex-direction: row;

          .material-icons {
            margin-top: -1px;
            margin-right: 8px;
          }
        }

        svg > g:first-child > g:first-child {
          display: none;
        }
      }
    }
  }
}
